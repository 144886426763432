import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeaderSecond from "./components/HeaderSecond";
import Footer from "components/Footer";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/fonts/stylesheet.css";
import "assets/scss/style.scss";
import styled from "styled-components/macro";

import HomePage from "pages/Home";

import { ThemeProvider } from "styled-components/macro";
import { lightTheme, GlobalStyles } from "./themes";

const PageContainer = styled.div`
  background: linear-gradient(to bottom, #e0e0e0 0%, #1a237e 100%);
  min-height: 100vh;
`;

// eslint-disable-next-line
function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      <Router>
        <PageContainer>
          <HeaderSecond />
          <Routes>
            <Route path="/" element={<HomePage />} />
          </Routes>
        </PageContainer>
        {/* <Footer /> */}
      </Router>
    </ThemeProvider>
  );
}

export default App;
