import styled, { keyframes } from 'styled-components/macro'

export const AboutSections = styled.div`
  background-color: #00000080;
  padding: 2rem 1rem;
  border-radius: 0.5rem;

  // div {
  //   background-color: rgba(0, 0, 0, 0.56);
  //   padding: 0.5rem;
  //   border-radius: 0.5rem;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: start;
  //   align-items: start;

  //   div {
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;
  //     gap: 1rem;
  //     background-color: transparent;

  //     img {
  //       width: 25px;
  //     }

  //     p {
  //       color: #fff;
  //       font-family: Montserrat;
  //       font-size: 1rem;
  //       font-weight: 500;
  //     }
  //   }
  // }
`

export const TextLarge = styled.h2`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 30px;
  color: #fff;
`

export const SmallText = styled.p`
  font-family: Montserrat;
  font-weight: 300;
  font-size: 16px;
  color: #fff;
`
export const ButtonStyled = styled.button`
  background-color: #fff;
  padding: 0.75rem 1rem;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  border: none;
  transition: all 0.5s ease;

  &:hover {
    transform: translateY(-0.5rem);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`
export const SecondSection = styled.div`
  position: relative;
  padding: 5rem 1rem;
  background: #fff;

  @media (min-width: 992px) {
    padding: 5rem 10rem;
  }
`
export const Link = styled.a`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  font-family: Montserrat;
  text-decoration: underline;
`

export const MediumWhiteText = styled.p`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.25rem;
  color: #fff;
  line-height: 1.25rem;
  margin: 1.5rem 0rem;
`
export const LinkAddress = styled.a`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.25rem;
  color: #fff;
  line-height: 1.25rem;
  text-align: center;
`
export const MediumBlackText = styled.p`
  font-family: Gamer;
  font-weight: 500;
  font-size: 4rem;
  color: #0f1723;
`
const textShine = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`
export const PresaleLink = styled.p`
  font-family: Montserrat;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  color: '#933bdc';
  background: linear-gradient(to right, #933bdc, #933bdc 33.33%, #fff 66.66%, #fff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 500% auto;
  animation: ${textShine} 1s linear infinite alternate;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.25rem;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
`
export const Col = styled.div`
  width: 100%;
  margin: 2rem 0.5rem;

  @media (min-width: 768px) {
    width: 90%;
  }

  @media (min-width: 992px) {
    width: 75%;
  }

  @media (min-width: 1400px) {
    width: 65%;
  }
`
const MoveUpDown = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1.5rem);
  }
`
export const FriezaImg = styled.img`
  cursor: pointer;
  transition: all 0.5s ease;
  width: 15rem;
  height: 20rem;
  animation: ${MoveUpDown} 1.5s linear infinite;
`
export const SupplyDiv = styled.div`
  width: fit-content;
  background-color: #00000080 !important;
  padding: 1rem !important;
  border-radius: 4px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: start !important;
  align-items: start !important;
  gap: 0.5rem;

  p {
    color: #fff;
    font-size: 1rem;
    font-family: Montserrat !important;
  }
`
export const Col65 = styled.div`
  width: 100%;
  div {
    background-color: #00000080;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  @media (min-width: 768px) {
    width: 90%;
  }

  @media (min-width: 992px) {
    width: 85%;
  }

  @media (min-width: 1400px) {
    width: 75%;
  }
`
