import styled from 'styled-components/macro'

export const FooterWrapper = styled.div`
  padding: rem;
  padding: 1.5rem;
`
export const FooterRefSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`
export const RefItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`
export const RefList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;

  &:before {
    display: inline-block;
    width: 1em;
    font-weight: lighter;
    content: '\25cf';
    color: #fff;
  }
`
export const RefTitle = styled.p`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 12px;
  line-height: 14.63px;
  color: #fff;
`
export const RefText = styled.p`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 14.63px;
  color: #fff;
`
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 3rem 0rem;
  background-color: #fff;
`
export const FooterContact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1rem;
  
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`
export const ImgContact = styled.img`
  width: 100%;
  height: 18px;
`
