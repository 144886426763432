import { Modal as BootstrapModal, ModalTitle } from 'react-bootstrap'
import styled from 'styled-components/macro'
import Friezameme from 'assets/images/frieza-meme-2.jpg'
import FriezaLogo from 'assets/images/frieza-logo.png'

const StyledModal = styled(BootstrapModal)`
  .modal-content {
    background-color: #fff;
    // max-width: 460px;
  }

  .modal-header {
    justify-content: center;
    align-items: center;
    border-bottom: none;
    color: #fff;
    font-family: Montserrat;
    /* padding: 3.75rem 2.5rem 0 2.5rem; */

    .btn-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  .modal-body {
    border-radius: 20px;
    padding: 2rem 2.5rem;
  }

  .modal-footer {
    border-top: 0;
  }
`
const StyledModalTitle = styled(ModalTitle)`
  color: #000;
`

interface IModalProps {
  size?: 'sm' | 'lg' | 'xl'
  className?: any
  show: boolean
  hideTitle?: boolean
  title: string | JSX.Element | JSX.Element[]
  children?: any
  button?: JSX.Element | JSX.Element[]
  onHide: () => void
  backdrop?: any
}

function Modal(props: IModalProps): JSX.Element {
  const { size, className, show, children, button, onHide, backdrop, title, hideTitle } = props

  return (
    <StyledModal className={className} autoFocus centered size={size} show={show} onHide={onHide} backdrop={backdrop}>
      <BootstrapModal.Header>
        <StyledModalTitle className={hideTitle ? 'screen-reader-text' : ''}>{title}</StyledModalTitle>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <p style={{ textAlign: 'center', fontFamily: 'Montserrat', fontSize: '1.5rem', fontWeight: '600'}}>Comming Soon !!!</p>
        <img src={FriezaLogo} />
      </BootstrapModal.Body>
      {button && <BootstrapModal.Footer>{button}</BootstrapModal.Footer>}
    </StyledModal>
  )
}

export default Modal
