import {
  FooterWrapper,
  FooterRefSection,
  RefTitle,
  RefText,
  RefItem,
  Divider,
  FooterContact,
  ImgContact,
} from "./Styled";

function Footer(): JSX.Element {
  return (
    <FooterWrapper>
      <p
        style={{
          fontFamily: "Montserrat",
          fontSize: 14,
          color: "#fff",
          marginBottom: "1rem",
        }}
      >
        &copy; 2023 #FriezaMeme
      </p>
    </FooterWrapper>
  );
}

export default Footer;
