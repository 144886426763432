import { useState } from "react";
import {
  Col,
  FriezaImg,
  PresaleLink,
  ButtonStyled,
  AboutSections,
  TextLarge,
  SupplyDiv,
  MediumWhiteText,
  Col65,
  LinkAddress,
} from "./Styled";
import "react-vertical-timeline-component/style.min.css";
import Frieza1 from "assets/images/fireza-1.png";
import Frieza2 from "assets/images/frieza-2.png";
import FriezaGold from "assets/images/frieza-gold.png";
import FriezaLogo from "assets/images/frieza-logo.png";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import FriezaMeme1 from 'assets/images/frieza-meme-1.jpg'
import FriezaMeme2 from 'assets/images/frieza-meme-2.jpg'
import FriezaMeme3 from 'assets/images/frieza-meme-3.png'
import Footer from "components/Footer";
import { shortenAddres } from "utils";
import Modal from "components/Modal";
import styled from "styled-components";

function HomePage(): JSX.Element {
  const [openModal, setOpenModal] = useState(false)
  return (
    <div className="d-flex justify-content-center">
      <Col>
        <div className="d-flex justify-content-center justify-content-lg-between align-items-center mb-5">
          <FriezaImg className="d-none d-lg-block" src={Frieza1} />
          <FriezaImg src={Frieza2} />
          <FriezaImg className="d-none d-lg-block" src={FriezaGold} />
        </div>
        <PresaleLink>Frieza Meme</PresaleLink>
        <MediumWhiteText
          style={{ textAlign: "center", color: "blue", fontWeight: "600" }}
        >
          Choose Freeza: Mystery, Tragedy, Power. Saiyans can't compete.
          #DragonBallBoss
        </MediumWhiteText>
        <div className="d-flex justify-content-center align-items-center gap-5 my-5">
          <ButtonStyled onClick={() => setOpenModal(true)}>Buy</ButtonStyled>
          <ButtonStyled onClick={() => setOpenModal(true)}>Chart</ButtonStyled>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-5 my-5">
          <a href="" target="_blank">
            <FaTelegramPlane color="#fff" size={50} />
          </a>
          <a href="https://x.com/FriezaMeme" target="_blank">
            <FaTwitter color="#fff" size={50} />
          </a>
        </div>
        <AboutSections id="about">
          <p
            style={{
              color: "#fff",
              fontFamily: "Montserrat",
              fontSize: "3rem",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            About Frieza Meme
          </p>
          <p
            style={{
              color: "#fff",
              fontFamily: "Montserrat",
              fontSize: "1.5rem",
              textAlign: "center",
              fontWeight: "500",
              margin: "2rem 0rem",
            }}
          >
            Frieza Meme
          </p>
          <div className="d-flex justify-content-center">
            <Col65>
              <div>
                <div
                  className="d-flex flex-col"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div
                    className="d-flex flex-row align-items-start gap-3"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <img src={FriezaLogo} width={23} />
                    <MediumWhiteText style={{ margin: 0 }}>
                      Frieza Tokens: Embrace Galactic Dominance with Mystical
                      Might!
                    </MediumWhiteText>
                  </div>
                  <MediumWhiteText
                    style={{
                      fontSize: "0.875rem",
                      marginLeft: "4rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    Elevate your portfolio with the same mystical power that
                    secured Frieza's dominance. Frieza Tokens offer an exclusive
                    edge, transcending Saiyan strength for a financial saga
                    that's as legendary as Dragon Ball itself.
                  </MediumWhiteText>
                </div>
                <div
                  className="d-flex flex-col"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div
                    className="d-flex flex-row align-items-start gap-3"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <img src={FriezaLogo} width={23} />
                    <MediumWhiteText style={{ margin: 0 }}>
                      Strategic Sorcery, Outsmart the Market!
                    </MediumWhiteText>
                  </div>
                  <MediumWhiteText
                    style={{
                      fontSize: "0.875rem",
                      marginLeft: "4rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    Unleash Frieza's cunning in the crypto arena! Frieza Tokens
                    boast a mystical financial strategy, a calculated move
                    beyond Saiyan brute force. Get ready to outsmart the market
                    and secure strategic victories with every investment.
                  </MediumWhiteText>
                </div>
                <div
                  className="d-flex flex-col"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div
                    className="d-flex flex-row align-items-start gap-3"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <img src={FriezaLogo} width={23} />
                    <MediumWhiteText style={{ margin: 0 }}>
                      Eternal Wealth Awaits: Join Frieza's Financial Empire!
                    </MediumWhiteText>
                  </div>
                  <MediumWhiteText
                    style={{
                      fontSize: "0.875rem",
                      marginLeft: "4rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    Secure your place in financial history with Frieza Tokens.
                    Like Frieza's design, your wealth will remain iconic. In a
                    market of transformations, Frieza's mystical empire beckons
                    you to invest in a saga of lasting prosperity.
                    #FriezaFinance #CryptoMystiqueWealth
                  </MediumWhiteText>
                </div>
              </div>
            </Col65>
          </div>
          <TextLarge style={{ textAlign: "center", margin: "5rem 0rem 2rem" }}>
            Tokenomics
          </TextLarge>
          <div
            className="d-flex flex-column flex-lg-row justify-content-center align-items-center gap-3 gap-lg-5"
            style={{ backgroundColor: "transparent" }}
          >
            <SupplyDiv>
              <p>Total supply</p>
              <p style={{ color: "gold" }}>420,000,000,000</p>
            </SupplyDiv>
          </div>
          <MediumWhiteText style={{ textAlign: "center" }}>
            Contract address
          </MediumWhiteText>
          <div className="d-flex flex-row justify-content-center">
            <LinkAddress
              href="/"
              target="_blank"
              style={{ textAlign: "center", color: "gold" }}
            >
              {/* {shortenAddres('svdsvdvfdvbfdvdvdvdsdsuh7f8evh8ehehwgwegfg3783gf788dgwfgds7hf78gw', 8)} */}
              Coming Soon !!!
            </LinkAddress>
          </div>
        </AboutSections>
        <div id="meme" style={{ margin: "2rem 0rem" }}>
          <TextLarge
            style={{ textAlign: "center", fontSize: "3rem", color: "#000" }}
          >
            Meme
          </TextLarge>
          <div className="row my-5">
            <div className="col-sm-9 col-md-6 col-lg-4">
              <img src={FriezaMeme1} height={300} />
            </div>
            <div className="col-sm-9 col-md-6 col-lg-4">
              <img src={FriezaMeme2} height={300} />
            </div>
            <div className="col-sm-9 col-md-6 col-lg-4">
              <img src={FriezaMeme3} height={300} />
            </div>
          </div>
        </div>
        <Footer />
      </Col>
      <Modal
        show={openModal}
        title=""
        hideTitle={true}
        onHide={() => setOpenModal(false)}
      />
    </div>
  );
}

export default HomePage;
