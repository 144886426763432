import { useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import {
  HeaderMain,
  HeaderWrapper,
  TitleWapper,
  MenuToggle,
  NavBarWrap,
  HeaderMobile,
  NavLinkA,
  ButtonWrapper,
} from './Styled'

import { FiMenu } from 'react-icons/fi'
import Logo from 'assets/images/telegram-cloud-photo-size-5-6174860853984212662-x-removebg-preview (2).png'
import Button from 'components/Button'
import FriezaLogo from 'assets/images/frieza-logo.png'
import Modal from 'components/Modal'

function HeaderSecond(): JSX.Element {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)


  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true)
  }

  return (
    <HeaderMain>
      <HeaderWrapper>
        <TitleWapper onClick={() => navigate('/')}>
          <div className='d-flex flex-row align-items-center'>
            <img src={FriezaLogo} height={50}/>
          </div>
        </TitleWapper>
        <NavBarWrap className="d-none d-lg-flex">
          <ul className="main-menu list-unstyled">
            <li>
              <NavLinkA href="/#about">About</NavLinkA>
            </li>
            <li>
              <NavLinkA href="/#meme" >Memes</NavLinkA>
            </li>
          </ul>
        </NavBarWrap>
        <ButtonWrapper>
          <Button type='outline' handleClick={() => setOpenModal(true)}>Buy</Button>
          <Button type='outline' handleClick={() => setOpenModal(true)}>Chart</Button>
        </ButtonWrapper>
      </HeaderWrapper>
      <HeaderMobile className="container">
        <TitleWapper onClick={() => navigate('/')}>
            <div className='d-flex flex-row align-items-center'>
              <img src={FriezaLogo} height={65}/>
            </div>
        </TitleWapper>
        <MenuToggle onClick={handleMobileMenuOpen}>
          <FiMenu size={24} />
        </MenuToggle>
        <Offcanvas show={mobileMenuOpen} onHide={() => setMobileMenuOpen(false)}>
          <Offcanvas.Header closeButton />
          <Offcanvas.Body>
          <ul className="main-menu list-unstyled">
            <li>
              <NavLinkA href="/#about">About</NavLinkA>
            </li>
            <li>
              <NavLinkA href="/#meme" >Memes</NavLinkA>
            </li>
          </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </HeaderMobile>
      <Modal show={openModal} title="" hideTitle={true} onHide={() => setOpenModal(false)} />
    </HeaderMain>
  )
}

export default HeaderSecond
